import { List, Datagrid, TextField, AutocompleteInput, TextInput, Edit, SimpleForm, EditButton } from "react-admin";
import { translateFilter } from "../../const/translate-filter.const";
import { Lang } from "../../enums/lang.enum";
const filters = [
    <AutocompleteInput label="Мова" source="locale" choices={translateFilter} alwaysOn />,
    <TextInput label="Значення" source="message" alwaysOn/>
];
export const TranslateList = (props: any) => (
    <List {...props} exporter={false} filters={filters} bulkActionButtons={false} filterDefaultValues={{ locale: Lang.UA }} sort={{ field: 'key_id', order: 'ASC' }}>
        <Datagrid>
            <TextField source="key_id" />
            <TextField source="message" />
            <EditButton label={'Редагувати'}/>
        </Datagrid>
    </List>
);

export const EditTranslation = (props: any) =>
  (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="message" label="Переклад" variant="outlined" />
      </SimpleForm>
    </Edit>
  );