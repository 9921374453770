import { FC } from "react";
import { NeedsShow, OrderList } from "../components/Recipients";
import { EditRole, RolesList, CreateRole } from "../components/Roles";
import { CreateSocialPost, SocialPostsList } from "../components/SocialPosts";
import { EditTranslation, TranslateList } from "../components/Translations";
import { UserShow, UserList } from "../components/Users";
import { VolunteerShow, VolunteerList, VolunteerListOnline } from "../components/Volunteers";
import { Roles } from "../enums/roles.enum";

export interface IResources {
    name: string;
    label?: string;
    show?: FC | undefined;
    list?: FC | undefined;
    create?: FC | undefined;
    edit?: FC | undefined;
    delete?: FC | undefined;
    role: string[] | null;
}

export const resourcesArray: IResources [] = [
    {
        name: 'ordersneeds',
        label: 'Заявки на потребу',
        show: NeedsShow,
        list: OrderList,
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'volunteer',
        label: 'Надання допомоги',
        show: VolunteerShow,
        list: VolunteerList,
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'volunteer-online',
        label: 'Надання допомоги онлайн',
        show: VolunteerShow,
        list: VolunteerListOnline,
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'community/social-posts',
        label: 'Пости',
        create: CreateSocialPost,
        list: SocialPostsList,
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'translation',
        label: 'Переклади',
        edit: EditTranslation,
        list: TranslateList,
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'roles',
        label: 'Дозволи',
        edit: EditRole,
        list: RolesList,
        create: CreateRole,
        role: [Roles.ADMIN]
    },
    {
        name: 'users',
        label: 'Користувачі',
        show: UserShow,
        list: UserList,
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'analytics',
        label: 'Аналітика',
        list: SocialPostsList,
        role: [Roles.ADMIN]
    },
    {
        name: 'ordersprovidelist',
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'geo_regions',
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'applications',
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'ordersneedslist',
        role: [Roles.ADMIN, Roles.EDITOR]
    },
    {
        name: 'users/comments',
        role: [Roles.ADMIN, Roles.EDITOR]
    }
]