export const country = [
    {
        "id": "ad",
        "name": "Andorra"
      },
      {
        "id": "ae",
        "name": "United Arab Emirates"
      },
      {
        "id": "af",
        "name": "Afghanistan"
      },
      {
        "id": "ag",
        "name": "Antigua and Barbuda"
      },
      {
        "id": "ai",
        "name": "Anguilla"
      },
      {
        "id": "al",
        "name": "Albania"
      },
      {
        "id": "am",
        "name": "Armenia"
      },
      {
        "id": "ao",
        "name": "Angola"
      },
      {
        "id": "aq",
        "name": "Antarctica"
      },
      {
        "id": "ar",
        "name": "Argentina"
      },
      {
        "id": "as",
        "name": "American Samoa"
      },
      {
        "id": "at",
        "name": "Austria"
      },
      {
        "id": "au",
        "name": "Australia"
      },
      {
        "id": "aw",
        "name": "Aruba"
      },
      {
        "id": "ax",
        "name": "Aland Islands"
      },
      {
        "id": "az",
        "name": "Azerbaijan"
      },
      {
        "id": "ba",
        "name": "Bosnia and Herzegovina"
      },
      {
        "id": "bb",
        "name": "Barbados"
      },
      {
        "id": "bd",
        "name": "Bangladesh"
      },
      {
        "id": "be",
        "name": "Belgium"
      },
      {
        "id": "bf",
        "name": "Burkina Faso"
      },
      {
        "id": "bg",
        "name": "Bulgaria"
      },
      {
        "id": "bh",
        "name": "Bahrain"
      },
      {
        "id": "bi",
        "name": "Burundi"
      },
      {
        "id": "bj",
        "name": "Benin"
      },
      {
        "id": "bl",
        "name": "Saint BarthÃ©lemy"
      },
      {
        "id": "bm",
        "name": "Bermuda"
      },
      {
        "id": "bn",
        "name": "Brunei"
      },
      {
        "id": "bo",
        "name": "Bolivia"
      },
      {
        "id": "bq",
        "name": "Caribbean Netherlands"
      },
      {
        "id": "br",
        "name": "Brazil"
      },
      {
        "id": "bs",
        "name": "Bahamas"
      },
      {
        "id": "bt",
        "name": "Bhutan"
      },
      {
        "id": "bv",
        "name": "Bouvet Island"
      },
      {
        "id": "bw",
        "name": "Botswana"
      },
      {
        "id": "by",
        "name": "Belarus"
      },
      {
        "id": "bz",
        "name": "Belize"
      },
      {
        "id": "ca",
        "name": "Canada"
      },
      {
        "id": "cc",
        "name": "Cocos  Islands"
      },
      {
        "id": "cf",
        "name": "Central African Republic"
      },
      {
        "id": "cg",
        "name": "Congo"
      },
      {
        "id": "ch",
        "name": "Switzerland"
      },
      {
        "id": "ci",
        "name": "CÃ´te dâIvoire"
      },
      {
        "id": "ck",
        "name": "Cook Islands"
      },
      {
        "id": "cl",
        "name": "Chile"
      },
      {
        "id": "cm",
        "name": "Cameroon"
      },
      {
        "id": "cn",
        "name": "China"
      },
      {
        "id": "co",
        "name": "Colombia"
      },
      {
        "id": "cr",
        "name": "Costa Rica"
      },
      {
        "id": "cu",
        "name": "Cuba"
      },
      {
        "id": "cv",
        "name": "Cape Verde"
      },
      {
        "id": "cw",
        "name": "CuraÃ§ao"
      },
      {
        "id": "cx",
        "name": "Christmas Island"
      },
      {
        "id": "cy",
        "name": "Cyprus"
      },
      {
        "id": "cz",
        "name": "Czech Republic"
      },
      {
        "id": "de",
        "name": "Germany"
      },
      {
        "id": "dj",
        "name": "Djibouti"
      },
      {
        "id": "dk",
        "name": "Denmark"
      },
      {
        "id": "dm",
        "name": "Dominica"
      },
      {
        "id": "do",
        "name": "Dominican Republic"
      },
      {
        "id": "dz",
        "name": "Algeria"
      },
      {
        "id": "ec",
        "name": "Ecuador"
      },
      {
        "id": "ee",
        "name": "Estonia"
      },
      {
        "id": "eg",
        "name": "Egypt"
      },
      {
        "id": "eh",
        "name": "Western Sahara"
      },
      {
        "id": "er",
        "name": "Eritrea"
      },
      {
        "id": "es",
        "name": "Spain"
      },
      {
        "id": "et",
        "name": "Ethiopia"
      },
      {
        "id": "fi",
        "name": "Finland"
      },
      {
        "id": "fj",
        "name": "Fiji"
      },
      {
        "id": "fk",
        "name": "Falkland Islands"
      },
      {
        "id": "fm",
        "name": "Micronesia"
      },
      {
        "id": "fo",
        "name": "Faroe Islands"
      },
      {
        "id": "fr",
        "name": "France"
      },
      {
        "id": "ga",
        "name": "Gabon"
      },
      {
        "id": "gb",
        "name": "United Kingdom"
      },
      {
        "id": "gd",
        "name": "Grenada"
      },
      {
        "id": "ge",
        "name": "Georgia"
      },
      {
        "id": "gf",
        "name": "French Guiana"
      },
      {
        "id": "gg",
        "name": "Guernsey"
      },
      {
        "id": "gh",
        "name": "Ghana"
      },
      {
        "id": "gi",
        "name": "Gibraltar"
      },
      {
        "id": "gl",
        "name": "Greenland"
      },
      {
        "id": "gm",
        "name": "Gambia"
      },
      {
        "id": "gn",
        "name": "Guinea"
      },
      {
        "id": "gp",
        "name": "Guadeloupe"
      },
      {
        "id": "gq",
        "name": "Equatorial Guinea"
      },
      {
        "id": "gr",
        "name": "Greece"
      },
      {
        "id": "gs",
        "name": "South Georgia & South Sandwich Islands"
      },
      {
        "id": "gt",
        "name": "Guatemala"
      },
      {
        "id": "gu",
        "name": "Guam"
      },
      {
        "id": "gw",
        "name": "Guinea-Bissau"
      },
      {
        "id": "gy",
        "name": "Guyana"
      },
      {
        "id": "hk",
        "name": "Hong Kong"
      },
      {
        "id": "hm",
        "name": "Heard Island and Mcdonald Islands"
      },
      {
        "id": "hn",
        "name": "Honduras"
      },
      {
        "id": "hr",
        "name": "Croatia"
      },
      {
        "id": "ht",
        "name": "Haiti"
      },
      {
        "id": "hu",
        "name": "Hungary"
      },
      {
        "id": "id",
        "name": "Indonesia"
      },
      {
        "id": "ie",
        "name": "Ireland"
      },
      {
        "id": "il",
        "name": "Israel"
      },
      {
        "id": "im",
        "name": "Isle of Man"
      },
      {
        "id": "in",
        "name": "India"
      },
      {
        "id": "io",
        "name": "British Indian Ocean Territory"
      },
      {
        "id": "iq",
        "name": "Iraq"
      },
      {
        "id": "ir",
        "name": "Iran"
      },
      {
        "id": "is",
        "name": "Iceland"
      },
      {
        "id": "it",
        "name": "Italy"
      },
      {
        "id": "je",
        "name": "Jersey"
      },
      {
        "id": "jm",
        "name": "Jamaica"
      },
      {
        "id": "jo",
        "name": "Jordan"
      },
      {
        "id": "jp",
        "name": "Japan"
      },
      {
        "id": "ke",
        "name": "Kenya"
      },
      {
        "id": "kg",
        "name": "Kyrgyzstan"
      },
      {
        "id": "kh",
        "name": "Cambodia"
      },
      {
        "id": "ki",
        "name": "Kiribati"
      },
      {
        "id": "km",
        "name": "Comoros"
      },
      {
        "id": "kn",
        "name": "Saint Kitts and Nevis"
      },
      {
        "id": "kp",
        "name": "North Korea"
      },
      {
        "id": "kr",
        "name": "South Korea"
      },
      {
        "id": "kw",
        "name": "Kuwait"
      },
      {
        "id": "ky",
        "name": "Cayman Islands"
      },
      {
        "id": "kz",
        "name": "Kazakhstan"
      },
      {
        "id": "la",
        "name": "Laos"
      },
      {
        "id": "lb",
        "name": "Lebanon"
      },
      {
        "id": "lc",
        "name": "Saint Lucia"
      },
      {
        "id": "li",
        "name": "Liechtenstein"
      },
      {
        "id": "lk",
        "name": "Sri Lanka"
      },
      {
        "id": "lr",
        "name": "Liberia"
      },
      {
        "id": "ls",
        "name": "Lesotho"
      },
      {
        "id": "lt",
        "name": "Lithuania"
      },
      {
        "id": "lu",
        "name": "Luxembourg"
      },
      {
        "id": "lv",
        "name": "Latvia"
      },
      {
        "id": "ly",
        "name": "Libya"
      },
      {
        "id": "ma",
        "name": "Morocco"
      },
      {
        "id": "mc",
        "name": "Monaco"
      },
      {
        "id": "md",
        "name": "Moldova"
      },
      {
        "id": "me",
        "name": "Montenegro"
      },
      {
        "id": "mf",
        "name": "Saint Martin"
      },
      {
        "id": "mg",
        "name": "Madagascar"
      },
      {
        "id": "mh",
        "name": "Marshall Islands"
      },
      {
        "id": "mk",
        "name": "Macedonia"
      },
      {
        "id": "ml",
        "name": "Mali"
      },
      {
        "id": "mm",
        "name": "Myanmar"
      },
      {
        "id": "mn",
        "name": "Mongolia"
      },
      {
        "id": "mo",
        "name": "Macau"
      },
      {
        "id": "mp",
        "name": "Northern Mariana Islands"
      },
      {
        "id": "mq",
        "name": "Martinique"
      },
      {
        "id": "mr",
        "name": "Mauritania"
      },
      {
        "id": "ms",
        "name": "Montserrat"
      },
      {
        "id": "mt",
        "name": "Malta"
      },
      {
        "id": "mu",
        "name": "Mauritius"
      },
      {
        "id": "mv",
        "name": "Maldives"
      },
      {
        "id": "mw",
        "name": "Malawi"
      },
      {
        "id": "mx",
        "name": "Mexico"
      },
      {
        "id": "my",
        "name": "Malaysia"
      },
      {
        "id": "mz",
        "name": "Mozambique"
      },
      {
        "id": "na",
        "name": "Namibia"
      },
      {
        "id": "nc",
        "name": "New Caledonia"
      },
      {
        "id": "ne",
        "name": "Niger"
      },
      {
        "id": "nf",
        "name": "Norfolk Island"
      },
      {
        "id": "ng",
        "name": "Nigeria"
      },
      {
        "id": "ni",
        "name": "Nicaragua"
      },
      {
        "id": "nl",
        "name": "Netherlands"
      },
      {
        "id": "no",
        "name": "Norway"
      },
      {
        "id": "np",
        "name": "Nepal"
      },
      {
        "id": "nr",
        "name": "Nauru"
      },
      {
        "id": "nu",
        "name": "Niue"
      },
      {
        "id": "nz",
        "name": "New Zealand"
      },
      {
        "id": "om",
        "name": "Oman"
      },
      {
        "id": "pa",
        "name": "Panama"
      },
      {
        "id": "pe",
        "name": "Peru"
      },
      {
        "id": "pf",
        "name": "French Polynesia"
      },
      {
        "id": "pg",
        "name": "Papua New Guinea"
      },
      {
        "id": "ph",
        "name": "Philippines"
      },
      {
        "id": "pk",
        "name": "Pakistan"
      },
      {
        "id": "pl",
        "name": "Poland"
      },
      {
        "id": "pm",
        "name": "Saint Pierre and Miquelon"
      },
      {
        "id": "pn",
        "name": "Pitcairn Islands"
      },
      {
        "id": "pr",
        "name": "Puerto Rico"
      },
      {
        "id": "ps",
        "name": "Palestine"
      },
      {
        "id": "pt",
        "name": "Portugal"
      },
      {
        "id": "pw",
        "name": "Palau"
      },
      {
        "id": "py",
        "name": "Paraguay"
      },
      {
        "id": "qa",
        "name": "Qatar"
      },
      {
        "id": "re",
        "name": "RÃ©union"
      },
      {
        "id": "ro",
        "name": "Romania"
      },
      {
        "id": "rs",
        "name": "Serbia"
      },
      {
        "id": "ru",
        "name": "Russia"
      },
      {
        "id": "rw",
        "name": "Rwanda"
      },
      {
        "id": "sa",
        "name": "Saudi Arabia"
      },
      {
        "id": "sb",
        "name": "Solomon Islands"
      },
      {
        "id": "sc",
        "name": "Seychelles"
      },
      {
        "id": "sd",
        "name": "Sudan"
      },
      {
        "id": "se",
        "name": "Sweden"
      },
      {
        "id": "sg",
        "name": "Singapore"
      },
      {
        "id": "sh",
        "name": "Saint Helena"
      },
      {
        "id": "si",
        "name": "Slovenia"
      },
      {
        "id": "sj",
        "name": "Svalbard and Jan Mayen"
      },
      {
        "id": "sk",
        "name": "Slovakia"
      },
      {
        "id": "sl",
        "name": "Sierra Leone"
      },
      {
        "id": "sm",
        "name": "San Marino"
      },
      {
        "id": "sn",
        "name": "Senegal"
      },
      {
        "id": "so",
        "name": "Somalia"
      },
      {
        "id": "sr",
        "name": "Suriname"
      },
      {
        "id": "ss",
        "name": "South Sudan"
      },
      {
        "id": "st",
        "name": "SÃ£o TomÃ© and PrÃ­ncipe"
      },
      {
        "id": "sv",
        "name": "El Salvador"
      },
      {
        "id": "sx",
        "name": "Sint Maarten"
      },
      {
        "id": "sy",
        "name": "Syria"
      },
      {
        "id": "sz",
        "name": "Swaziland"
      },
      {
        "id": "tc",
        "name": "Turks and Caicos Islands"
      },
      {
        "id": "td",
        "name": "Chad"
      },
      {
        "id": "tf",
        "name": "French Southern Territories"
      },
      {
        "id": "tg",
        "name": "Togo"
      },
      {
        "id": "th",
        "name": "Thailand"
      },
      {
        "id": "tj",
        "name": "Tajikistan"
      },
      {
        "id": "tk",
        "name": "Tokelau"
      },
      {
        "id": "tl",
        "name": "Timor-Leste"
      },
      {
        "id": "tm",
        "name": "Turkmenistan"
      },
      {
        "id": "tn",
        "name": "Tunisia"
      },
      {
        "id": "to",
        "name": "Tonga"
      },
      {
        "id": "tr",
        "name": "Turkey"
      },
      {
        "id": "tt",
        "name": "Trinidad and Tobago"
      },
      {
        "id": "tv",
        "name": "Tuvalu"
      },
      {
        "id": "tw",
        "name": "Taiwan"
      },
      {
        "id": "tz",
        "name": "Tanzania"
      },
      {
        "id": "ua",
        "name": "Ukraine"
      },
      {
        "id": "ug",
        "name": "Uganda"
      },
      {
        "id": "um",
        "name": "U.S. Minor Outlying Islands"
      },
      {
        "id": "us",
        "name": "United States"
      },
      {
        "id": "uy",
        "name": "Uruguay"
      },
      {
        "id": "uz",
        "name": "Uzbekistan"
      },
      {
        "id": "va",
        "name": "Vatican City"
      },
      {
        "id": "vc",
        "name": "Saint Vincent and the Grenadines"
      },
      {
        "id": "ve",
        "name": "Venezuela"
      },
      {
        "id": "vg",
        "name": "British Virgin Islands"
      },
      {
        "id": "vi",
        "name": "U.S. Virgin Islands"
      },
      {
        "id": "vn",
        "name": "Vietnam"
      },
      {
        "id": "vu",
        "name": "Vanuatu"
      },
      {
        "id": "wf",
        "name": "Wallis and Futuna"
      },
      {
        "id": "ws",
        "name": "Samoa"
      },
      {
        "id": "xk",
        "name": "Kosovo"
      },
      {
        "id": "ye",
        "name": "Yemen"
      },
      {
        "id": "yt",
        "name": "Mayotte"
      },
      {
        "id": "za",
        "name": "South Africa"
      },
      {
        "id": "zm",
        "name": "Zambia"
      },
      {
        "id": "zw",
        "name": "Zimbabwe"
      }
]