import { List, Datagrid, DateField, ReferenceField, TextField, SimpleShowLayout, Show, ShowButton, NumberField, DateInput, TextInput, TabbedShowLayout, Tab, AutocompleteInput, BulkUpdateButton, FormInput } from "react-admin";
import UserComments from "../userComments";
import { stateAction, stateTabs } from "../../const/state-tabs.const";
import { stateFilter } from "../../const/state-filter.const";
import { helpTypeFilter } from "../../const/help-type-filter.const";
import LargeTextFiled from "../../utils/MinimizeTextFiled";
const postFilters = [
    <AutocompleteInput label="Статус" source="state" choices={stateFilter} alwaysOn />,
    <DateInput label="Від" source="from" />,
    <DateInput label="До" source="to" />,
    <TextInput label="Місто" source="locality_name" alwaysOn/>,
    <TextInput label="Телефон" source="phone_number" alwaysOn/>,
    <TextInput label="П.І.Б." source="contact_name" />,
];
const filterCertType = [
    <AutocompleteInput label="Тип сертифікату" source="types" choices={helpTypeFilter} alwaysOn />,
]
export const VolunteerList = (props: any) => (
    <VolunteerListFields {...props} filters={[...postFilters, ...filterCertType]}/>
);
export const VolunteerListOnline = (props: any) => (
    <VolunteerListFields {...props} filters={[...postFilters, ...filterCertType]}/>
);

const VolunteerListFields = (props: any) => (
    <List {...props} exporter={false} filters={props.filters} filterDefaultValues={{ state: 'in_progress' }} sort={{ field: 'last_update', order: 'DESC' }}>
        <Datagrid>
            <DateField label="Остання заявка" locales="uk-ua" source="last_update" />
            <TextField label="П.І.Б" source="contact_name" emptyText="Гість" />
            <TextField label="Місто" source="locality_name" />
            <TextField label="Організація" source="legal_name" />
            <TextField label="Телефон" source="phone_number" />
            <TextField label="Пошта" source="email" />
            <ShowButton label="Переглянути"/>
        </Datagrid>
    </List>
);

export const VolunteerShow = (props: any) => {
    return(
    <Show {...props}>
        <SimpleShowLayout>
            <h3>{'Профайл благодійника'}</h3>
            <TextField label="П.І.Б" source="contact_name" emptyText="Гість" />
            <TextField label="Область" source="region_name" />
            <TextField label="Місто" source="locality_name" />
            <TextField label="Організація" source="legal_name" />
            <TextField label="Телефон" source="phone_number" />
            <TextField label="Пошта" source="email" />
            <TextField label="Тип" source="type" />
            <UserComments {...{...props, userId: props.id}}/>

            <h3>{'Надання допомоги'}</h3>
            < ViewNeeds {...props}/>
        </SimpleShowLayout>
       
    </Show>
)};

export const ViewNeeds = (props: any) => {
  const view = stateTabs.map((v) => {
        const {state,lable} = v;
        return (
            <Tab label={lable} key={state}>
                <NeedsGrid {...{...props, state}}/>
            </Tab>
        )
    });
    return(
    <TabbedShowLayout>
       {view}
    </TabbedShowLayout>
    )};

const needsFilter = [
    <TextInput label="ІД" source="id_c" alwaysOn/>,
    <TextInput label="Телефон" source="phone_number_c" alwaysOn/>,
    <TextInput label="П.І.Б." source="contact_name_c" alwaysOn/>,
];
export const NeedsGrid = (props: any) => {
    return (
    <List {...props} exporter={false} bulkActionButtons={<DonateActionButtons />} filters={needsFilter} filter={{ user_id: props.id, state: props.state }}>
        <Datagrid>
            <DateField label="Додано" source="ts" locales="uk-ua"/>
            <DateField label="В роботу" source="in_progress_at" locales="uk-ua"/>
            <DateField label="Оновлено" source="update_time" locales="uk-ua"/>
            <TextField label="Користувач" source="user_id" />
            <TextField label="Допомога" source="item_name" />
            <NumberField label="Кількість" source="amount" />
            <TextField label="Одиниць" source="unit" />
            <ReferenceField label="П.І.Б" sortBy="contact_name" source="user_id" reference="users" link="show">
                <TextField source="contact_name" />
            </ReferenceField>
            <TextField label="Область" source="region_name" />
            <TextField label="Місто" source="locality_name" />
            <TextField label="Телефон" source="phone_number" />
            <TextField label="Пошта" source="email" />
            <TextField label="Район" source="district" />
            <TextField label="Вулиця" source="street_name" />
            <TextField label="Будинок" source="building_number" />
            <LargeTextFiled label="Опис" source="user_description" slice={50}/>
            <LargeTextFiled label="Лист до Санти" source="santa_letter" slice={50}/>
        </Datagrid>
    </List>
)};

const DonateActionButtons = (props: any) => (
    <>
        {
            stateAction.map(action => <BulkUpdateButton
                {...props}
                key={action.state}
                label={action.lable}
                data={{state: action.state}}
                icon={action.icon}
            />)
        }
    </>
);