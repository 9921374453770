import { List, Datagrid, TextField, ReferenceField, Show, SimpleShowLayout, TextInput, ShowButton, FunctionField, AutocompleteInput } from "react-admin";
import { country } from "../../const/country.const";
import { userTypeFilter } from "../../const/state-filter.const";
import LargeTextFiled from "../../utils/MinimizeTextFiled";
import { ParticipantViewNeeds } from "../Recipients";
import UserComments from "../userComments";
import { VolunteerViewHelp } from "./VolunteerHelp";
const postFilters = [
    
    <TextInput label="Телефон" source="phone_number" alwaysOn/>,
    <TextInput label="П.І.Б." source="contact_name" alwaysOn/>,
    <AutocompleteInput label="Тип користувача" source="type" choices={userTypeFilter} alwaysOn />,
    <AutocompleteInput label="Країна" source="country" choices={country} alwaysOn />,
];
export const UserList = (props: any) => (
    <List {...props} filters={postFilters} exporter={false} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField label="П.І.Б" source="contact_name" />
            <ReferenceField label="Область" source="region_id" reference="geo_regions">
                <TextField source="name" />
            </ReferenceField>
            <TextField label="Телефон" source="phone_number" />
            <TextField label="Місто" source="locality_name" />
            <TextField label="Країна" source="name" />
            <FunctionField label="Користувач" render={(record: any) => `${record.type === 'in_need' ? 'Потребуючий' : 'Благодійник'}`} />
            <ShowButton label="Переглянути"/>
        </Datagrid>
    </List>
);

export const UserShow = (props: any) => {
    return( <Show {...props}>
        <SimpleShowLayout >
            <ReferenceField label="П.І.Б" source="id" reference="users">
                <TextField source="contact_name" />
            </ReferenceField>
            <TextField label="Місто" source="locality_name" />
            <FunctionField label="Користувач" render={(record: any) => `${record.type === 'in_need' ? 'Потребуючий' : 'Благодійник'}`} />
            <TextField label="Організація" source="legal_name" />
            <TextField label="Телефон" source="phone_number" />
            <TextField label="Пошта" source="email" />
            <TextField label="Область" source="region_name" />
            <TextField label="Район" source="district" />
            <TextField label="Вулиця" source="street_name" />
            <TextField label="Будинок" source="building_number" />
            <h4>{'Опис'}</h4>
            <LargeTextFiled label="Опис" source="description" slice={50}/>
            <UserComments {...{...props, userId: props.id}}/>

            <h3>{'Надання допомоги'}</h3>
            <ShowTableOrders {...props} />
        </SimpleShowLayout>
       
    </Show>)
};

export const ShowTableOrders = (props: any) => {
    const {id: user_id } = props;
    return (<>{props.record?.type === 'volunteer' ?
     < VolunteerViewHelp {...{...props, user_id}} /> : 
     < ParticipantViewNeeds {...{...props, user_id}}/>}</>);
}