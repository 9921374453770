const {
    REACT_APP_FB_API_KEY="AIzaSyDDqvme8JHiurygmgXOf0MRhBEVUSI2e1I",
    REACT_APP_FB_AUTH_DOMAIN="velvety-rarity-226921.firebaseapp.com",
    REACT_APP_FB_PROJECT_ID="velvety-rarity-226921",
    REACT_APP_FB_STORAGE_BUCKET="velvety-rarity-226921.appspot.com",
    REACT_APP_FB_MESSAGING_SENDER_ID="358127142708",
    REACT_APP_FB_APP_ID="1:358127142708:web:2ac3a79d08c637cda28761",
    REACT_APP_FB_MEASUREMENT_ID="G-H50CQEKKY6",
    REACT_APP_API_URL="https://api.edopomoga.gov.ua/_api",
} = process.env

export const config = {
    apiUrl: REACT_APP_API_URL,
    fireBaseConfig: {
        apiKey: REACT_APP_FB_API_KEY,
        authDomain: REACT_APP_FB_AUTH_DOMAIN,
        projectId: REACT_APP_FB_PROJECT_ID,
        storageBucket: REACT_APP_FB_STORAGE_BUCKET,
        messagingSenderId: REACT_APP_FB_MESSAGING_SENDER_ID,
        appId: REACT_APP_FB_APP_ID,
        measurementId: REACT_APP_FB_MEASUREMENT_ID
    }
};
