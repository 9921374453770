import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

const useUserPermissions = (permissions: {email: string}) => {
    const [scope, setScope] = useState<string | null>(null);
    const dataProvider = useDataProvider();
    useEffect(() => {
        if (permissions && !scope) {
            const { email: userEmail } = permissions;
            const getPerm = async () => {
                const {data} = await dataProvider.getList('roles', {
                    pagination: 
                    {
                        page: 1,
                        perPage: 1
                    }, 
                    sort: {
                        field: 'id', 
                        order: 'ASC'
                    },
                    filter: {email: userEmail}
                });
                if (data && data.length) 
                    setScope(data[0].roles)
            }
            
            getPerm();
        }
    }, [dataProvider, permissions, scope])

    return scope
}

export default useUserPermissions;