import { ReferenceManyField, Tab, TabbedShowLayout } from "react-admin";
import { stateTabs } from "../../const/state-tabs.const";
import { ListOfHelp } from "../Recipients";

export const VolunteerViewHelp = (props: any) => {
    const user_id = props.user_id ?? props.record.user_id;
    const view = stateTabs.map((v) => {
          const {state,lable} = v;
          return (
              <Tab label={lable} key={state}>
                  <ViewOrderNeeds {...{...props, state, user_id}}/>
              </Tab>
          )
      });
      return(
      <TabbedShowLayout>
         {view}
      </TabbedShowLayout>
)};
const ViewOrderNeeds = (props: any) => (
    <ReferenceManyField  reference="ordersneedslist" perPage={10} 
    filter={{state: props.state, volunteer_id: props.user_id}}
    target="id" addLabel={false}>
        <ListOfHelp {...props}/>
    </ReferenceManyField>
)