import { FC, useMemo } from "react";
import { Loading, useGetList } from "react-admin";
import AddNewUserComment from "./AddComment";
import UserCommentsList from "./UserCommentsList";

interface IUserCommentProps {
    userId: number;
}
const UserComments: FC<IUserCommentProps> = (props) => {
    const userId = useMemo(() => props.userId, [props.userId]);
    
    const { data, ids, loading, total, error } = useGetList(
        `users/comments`, undefined, undefined, {userId}
    );
    if (loading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
    return(
        <div className="user-comments-block">
            <h3>Коментарі ({total ?? ''})</h3>
            <AddNewUserComment userId={userId}/>
            <UserCommentsList ids={ids} data={data}/>
        </div>
    );
}

export default UserComments;