import { FC, useMemo, useState } from "react";
import { TextFieldProps } from "react-admin";

const LargeTextFiled: FC<TextFieldPropsMinimize> = ({source, slice, record, minWidth = '200px' }) => {
    const [expandState, setExpandState] = useState<boolean>(false);
    const text = useMemo(() =>  record && record[source] ? record[source] : '', [record, source]);
    const formateText = useMemo(() =>  slicing(text, expandState, slice), [expandState, slice, text]);

    const changeMoreHandler = () => {
        setExpandState(prev => prev ? false : true);
    }
    
    const button = (
        <button className="minButton" onClick={changeMoreHandler}>{expandState ? 'less' : '... more'}</button>   
    );
    return (
        <div style={{minWidth}}>
            <span>{formateText}</span>{text.split('').length >= slice && button}
        </div> 
    );
}

export default LargeTextFiled;

const slicing = (text: string, expandState: boolean, slice: number): string => {
    return expandState ? text : text.substring(0, slice);
}
export interface TextFieldPropsMinimize
    extends TextFieldProps {
    source: string;
    slice: number;
    minWidth?: string;
}
