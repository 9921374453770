import { FC, useMemo, useState } from "react"
import { Identifier, RecordMap } from "react-admin";
import { IUserComment } from "../types";
import UserComment from "../UserComment";
import "./style.css"

const UserCommentsList: FC<{ids: Identifier[], data: RecordMap<any>}> = ({ids, data}) => {
    const [more, setMore] = useState<boolean>(false);
    const showIds = useMemo(() => more ? ids : ids.slice(0, 3), [more, ids])
    return (
        <>
            {showIds && showIds.length ? showIds.map( id => 

                    <UserComment key={id} comment={data[id] as IUserComment} />

                ) : <p>Немає жодного коментаря...</p>}
                
            {ids.length > 3 && 
                <button className="comments-show-more" onClick={() => setMore(prev => prev ? false: true)}>
                    {more ? 'згорнути' : 'показати всі'}
                </button>}
        </>
    );
};

export default UserCommentsList;