import {Check, DeleteOutline, Pause, LowPriority, Autorenew, PublishRounded } from '@material-ui/icons';
import { OrderStates } from '../enums/order-state.enum';

export const stateTabs = [
    {
        lable: "В роботі",
        state: OrderStates.IN_PROGRESS,
        icon: <Autorenew/>
    },
    {
        lable: "Оброблено",
        state: OrderStates.PROCESSED,
        icon: <PublishRounded />
    },
    {
        lable: "Виконано",
        state: OrderStates.COMPLETED,
        icon: <Check/>
    },
    {
        lable: "На утриманні",
        state: OrderStates.ON_HOLD,
        icon: <Pause />
    },
    {
        lable: "Відмінено",
        state: OrderStates.CANCELED,
        icon: <DeleteOutline />
    },
]

export const stateAction = [
    ...stateTabs,
    {
        lable: "Відєднати",
        state: OrderStates.NEW,
        icon: <LowPriority />
    }
]


export const participantOrderState = [
    {
        lable: "Нові",
        state: OrderStates.NEW,
        icon: <LowPriority />
    },
    ...stateTabs
]