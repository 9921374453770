import { Fragment, useEffect, useState } from "react";
import { Check } from '@material-ui/icons';
import { List, Datagrid, TextField, ReferenceField, NumberField, TextInput, DateField, DateInput, ShowButton, ReferenceManyField, Show, SimpleShowLayout, AutocompleteInput, BulkUpdateButton, TabbedShowLayout, Tab, FunctionField } from "react-admin";
import UserComments from "../userComments";
import { helpTypeFilter } from "../../const/help-type-filter.const";
import { participantOrderState } from "../../const/state-tabs.const";
import LargeTextFiled from "../../utils/MinimizeTextFiled";
const postFilters = [
    <DateInput label="Від" source="from" alwaysOn/>,
    <DateInput label="До" source="to" alwaysOn/>,
    <TextInput label="Місто" source="locality_name" alwaysOn/>,
    <TextInput label="Телефон" source="phone_number" alwaysOn/>,
    <TextInput label="П.І.Б." source="contact_name" alwaysOn/>,
    <AutocompleteInput label="Тип допомоги" source="types" choices={helpTypeFilter} alwaysOn />,
];
export const OrderList = (props: any) => {
    const [types, setTypes] = useState<number>(0);
    useEffect(() => {
        const filter: any = getParameterByName('filter');
        if (!filter) return;
        const jsonFilter = JSON.parse(filter);
        setTypes(jsonFilter.types ?? 0);
    });
    
    return (

    <List {...props} exporter={false} bulkActionButtons={types ? <ZohoButton /> : <EmptyActionButtons />} filters={postFilters} sort={{ field: 'ts', order: 'DESC' }}>
        <Datagrid>
            <TextField label="Користувач" source="user_id" />
            <DateField label="Додано" source="ts" />
            <TextField label="Організація" source="legal_name" />
            <ReferenceField label="П.І.Б" source="user_id" reference="users" link={false}>
                <TextField source="contact_name" />
            </ReferenceField>
            <TextField label="Місто" source="locality_name" />
            <TextField label="Телефон" source="phone_number" />
            <TextField label="Пошта" source="email" />
            <ShowButton label="Переглянути"/>
        </Datagrid>
    </List>
)};

const EmptyActionButtons = () => (
    <Fragment>
        Оберіть тип допомоги для передачі в ZOHO
    </Fragment>
);

const ZohoButton = (props: any) => {
    return (
  <BulkUpdateButton
        label="Відправити в Zoho"
        data={{filter: props.filterValues}}
        icon={<Check/>}
    />
)
};

const DetailsView = (props: any) => {
    return (
        <SimpleShowLayout {...props}>
            <h3>{'Профайл потребуючого'}</h3>
            <TextField label="Користувач" source="user_id" />
            <DateField label="Додано" source="ts" />
            <TextField label="Організація" source="legal_name" />
            <ReferenceField label="П.І.Б" source="user_id" reference="users" link="show">
                <TextField source="contact_name" />
            </ReferenceField>
            <TextField label="Місто" source="locality_name" />
            <TextField label="Телефон" source="phone_number" />
            <TextField label="Пошта" source="email" />
            <h4>{'Опис'}</h4>
            <LargeTextFiled label="Опис" source="description" slice={50}/>
            <UserComments {...{...props, userId: props.record.user_id}}/>
            <h3>{'Перелік потреб'}</h3>
            <ParticipantViewNeeds {...props} />
        </SimpleShowLayout>
    );
} 

export const NeedsShow = (props: any) => {
    return(
    <Show {...props}>
        <DetailsView {...props}/>
    </Show>
)};
export const ParticipantViewNeeds = (props: any) => {
    const user_id = props.user_id ?? props.record.user_id;
    const view = participantOrderState.map((v) => {
          const {state,lable} = v;
          return (
              <Tab label={lable} key={state}>
                  <ViewOrderNeeds {...{...props, state, user_id}}/>
              </Tab>
          )
      });
      return(
      <TabbedShowLayout>
         {view}
      </TabbedShowLayout>
)};
const ViewOrderNeeds = (props: any) => (
    <ReferenceManyField  reference="ordersneedslist" perPage={10} 
    filter={{state: props.state, user_id: props.user_id}}
    sort={{field: props.state !== 'new' ? 'in_progress_at' : 'create_ts', order: 'DESC'}}
    target="id" addLabel={false}>
        <ListOfHelp {...props}/>
    </ReferenceManyField>
)

export const ListOfHelp = (props: any) => (
    <List {...props} exporter={false} bulkActionButtons={false} filters={[]}>
        <Datagrid>
            <DateField label="Створена" source="create_ts" />
            {props.state !== 'new' && <DateField label="Взято в роботу" source="in_progress_at" />}
            <TextField label="Тип допомоги" source="cat_name" />
            <TextField label="Допомога" source="item_name" />
            <NumberField label="Кількість" source="amount" />
            <TextField label="Одиниць" source="unit" />
            <FunctionField label="Тип допомоги" render={(record: any) => `${props.state !== 'new' ? (record.purchase_id ? 'Онлайн' : 'Із рук в руки') : ''}`} />
        </Datagrid>
    </List>
)
// TODO move to hook
const getParameterByName = (name: string, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}