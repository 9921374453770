import React from "react";
import { Login } from "react-admin";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase/compat/app";
// Configure FirebaseUI.
const uiConfig: any = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '#/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  // Optional callbacks in order to get Access Token from Google,Facebook,... etc
  callbacks: {
    signInSuccessWithAuthResult: (result: any) => {
      const credential = result.credential;
      // The signed-in user info.
      const user = result.user;
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const accessToken = credential.idToken;
      sessionStorage.setItem('token', accessToken);
      sessionStorage.setItem('email', result.additionalUserInfo.profile.email);
      window.location.reload();
      console.log({result, user, accessToken});
    }
  }
};


const CustomLoginForm = (props: any) => {
    return(
    <div>
        <StyledFirebaseAuth 
            firebaseAuth={firebase.auth()}
            uiConfig={uiConfig}
        />
    </div>
    )
};

const CustomLoginPage = (props: any) => (
    <Login {...props}>
        <CustomLoginForm {...props}/>
    </Login>
);

export default CustomLoginPage;
