import * as React from "react";
import { AdminContext, fetchUtils } from 'react-admin';
import { FirebaseAuthProvider } from "react-admin-firebase";
import './App.css';
import jsonServerProvider from 'ra-data-json-server';
import { config } from "./config";
import Resources from "./components/Resources";

const {apiUrl, fireBaseConfig} = config;
    
const authProvider = FirebaseAuthProvider(fireBaseConfig,{});
      
const httpClient = async (url:string, options: any = {}) => {
    const _options = options;
    if (_options && !_options.headers) {
      _options.headers = new Headers({ Accept: 'application/json'});
    }
    const token = await authProvider.getJWTToken();
    _options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, _options);
};
    
const dataProvider = jsonServerProvider(apiUrl, httpClient);

const App: React.FC = () => {
  return (  
    <AdminContext dataProvider={dataProvider} authProvider={authProvider}>
      <Resources />
    </AdminContext>
  )
};

export default App;