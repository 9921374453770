import { Create, Datagrid, List, SimpleForm, TextField, TextInput, UrlField } from "react-admin";

export const SocialPostsList = (props: any) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="type" />
            <TextField source="title" />
            <UrlField source="url" />
        </Datagrid>
    </List>
);

export const CreateSocialPost = (props: any) => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" label="Назва" variant="outlined" />
        <TextInput source="url" label="Посилання на пост" variant="outlined" />
      </SimpleForm>
    </Create>
  );