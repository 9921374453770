import { FC, useCallback, useMemo } from "react";
import { AdminUI, Resource, usePermissions } from "react-admin";
import { IResources, resourcesArray } from "../../const/resourses.const";
import useUserPermissions from "../../hooks/useUserPermission";
import CustomLoginPage from "../loginPage";

const useInitProps = (scope: string | null) => {
    const validate = useCallback((item: IResources, source: 'show' | 'edit' | 'create' | 'list' | 'delete') => {
        return scope && item.role?.includes(scope) ? (item[source as keyof IResources]) as FC : undefined;
    } , [scope])

    const getProps = useCallback((item: IResources) => ({
        ...item,
        options: { label: item.label },
        key: item.name,
        show: validate(item, 'show'),
        list: validate(item, 'list'),
        edit: validate(item, 'edit'),
    }), [validate]);

    return [getProps];
}

const Resources = () => {
    const { permissions } = usePermissions();

    const scope = useUserPermissions(permissions);
    const [getProps] = useInitProps(scope);

    const resources = useMemo(() => resourcesArray.map((item => <Resource {...getProps(item)} />)), [getProps]); 

    return (
        <AdminUI loginPage={CustomLoginPage} title={'Управління гуманітарною допомогою'}>
            {resources}
        </AdminUI>
)

}
export default Resources;