import { OrderStates } from "../enums/order-state.enum";
import { UserType } from "../enums/user-type.enum";

export const stateFilter = [
    { id: OrderStates.IN_PROGRESS, name: 'В роботі' },
    { id: OrderStates.ON_HOLD, name: 'На утриманні' },
    { id: OrderStates.PROCESSED, name: 'Оброблено' },
    { id: OrderStates.CANCELED, name: 'Відмінено' },
    { id: OrderStates.COMPLETED, name: 'Виконано' },
]

export const userTypeFilter = [
    { id: UserType.Volunteer, name: 'Благодійник' },
    { id: UserType.Recipient, name: 'Реціпієнт' }
]