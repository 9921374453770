import { FC, useMemo } from "react";
import { IUserComment } from "../types";
import Moment from 'moment';
import "./style.css";

const UserComment: FC<{comment: IUserComment}> = ({comment}) => {
    const {create_ts, created, message} = comment;
    const data = useMemo(() => Moment(create_ts).format("DD.MM.YYYY hh:mm"), [create_ts]);
    return (
        <div className="comment-block">
            <div className="comment-head">
                <span className="comment-author">{created}</span>
                <span className="comment-date">{data}</span>
            </div>
            <p className="comment-msg">{message}</p>
        </div>
    );
}

export default UserComment;