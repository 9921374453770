import { HelpTypes } from "../enums/help-type.enum";

export const helpTypeFilter = [
    { id: HelpTypes.MEDICINE, name: 'Ліки' },
    { id: HelpTypes.FOOD, name: 'Продукти' },
    { id: HelpTypes.FUEL, name: 'Пальне' },
    { id: HelpTypes.HYGIENE, name: 'Гігієна' },
    { id: HelpTypes.CHILDREN_GOODS, name: 'Дитячі товари' },
    { id: HelpTypes.CLOTHES, name: 'Одяг' },
    { id: HelpTypes.SANTA, name: 'Лист до Санти' },
]