import { List, Datagrid, TextField, EditButton, Create, SimpleForm, TextInput, Edit } from "react-admin";

export const RolesList = (props: any) => (
    <List {...props}>
        <Datagrid>
            <TextField label={'Електронная адреса'} source="email" />
            <TextField label="Дозволи" source="roles" />
            <EditButton label={'Редагувати'}/>
        </Datagrid>
    </List>
);

export const CreateRole = (props: any) =>
  (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="email" label="Електронная адреса" variant="outlined" />
        <TextInput source="roles" label="Дозволи" variant="outlined"/>
      </SimpleForm>
    </Create>
  );

  export const EditRole = (props: any) =>
  (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="email" label="Електронная адреса" variant="outlined" />
        <TextInput source="roles" label="Дозволи" variant="outlined"/>
      </SimpleForm>
    </Edit>
  );