import { FC, useState } from "react";
import { useCreate, useRefresh } from "react-admin";
import "./style.css";

const AddNewUserComment:FC<{userId: number}> = ({userId}) => {
    const [message, setMessage] = useState<string>('');
    const refresh = useRefresh();
    const [create, { loading }] = useCreate(undefined, undefined, {
        onSuccess: () => {
            setMessage('');
            refresh();
        }
    });

    const changeValueHandler = (event: any) => {
        setMessage(event.target.value);
    }
    const saveCommentHandler = () => {
        create('users/comments', {userId, message})
    }
    return (
        <div className="user-comment-edit-form">
            <input type={"text"} value={message} placeholder="Додайте новий коментар ..." onChange={changeValueHandler}/>
            <button disabled={loading || message === ''} onClick={saveCommentHandler}>Додати</button>
        </div>
    );
} 

export default AddNewUserComment;